<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.userAccount.title") }}</h3>
    <b-row class="no-gutters justify-content-center">
      <b-card no-body class="w-100 position-relative card-container">
        <b-dropdown class="avatar-wrapper" right no-caret>
          <template v-slot:button-content>
            <b-avatar class="avatar clickable" :src="accountInfo.avatar.src" />
            <b-icon class="avatar-caption" icon="pencil-square" />
          </template>
          <b-dropdown-item
            v-for="(action, index) in profilePictureActions"
            :key="index"
            @click="action.callback()"
          >
            {{ $t(action.label) }}
          </b-dropdown-item>
        </b-dropdown>
        <b-card-body class="p-3 card-body-container">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <b-form>
              <b-row class="no-gutters">
                <b-col class="col-6 pr-1">
                  <form-input
                    v-model="accountInfo.name"
                    label="name"
                    veeName="name"
                    :veeRules="{ required: true }"
                  />
                </b-col>
                <b-col class="col-6 pl-1">
                  <form-input
                    v-model="accountInfo.surname"
                    label="surname"
                    veeName="surname"
                    :veeRules="{ required: true }"
                  />
                </b-col>
                <b-col class="col-6 pr-1">
                  <form-input
                    v-model="accountInfo.email"
                    label="email"
                    disabled
                  />
                </b-col>
                <b-col class="col-6 pl-1">
                  <form-input
                    v-model="accountInfo.telephone"
                    label="telephone"
                    disabled
                  />
                </b-col>
              </b-row>
            </b-form>
            <hr class="my-3 mx-n3" />
            <b-row class="no-gutters justify-content-end">
              <b-col class="col-auto">
                <b-button
                  variant="primary"
                  class="text-white"
                  :disabled="invalid"
                  block
                  @click="saveProfileChanges"
                >
                  {{ $t("button.saveChanges") }}
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-row>

    <file-uploader ref="selectAvatar" @setFiles="setProfilePicture" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { authMixin, userMixin } from "./../mixins";

import FormInput from "../components/Base/BaseFormInput";
import FileUploader from "../components/Base/BaseFileUploader";
export default {
  components: {
    FormInput,
    FileUploader
  },
  mixins: [authMixin, userMixin],
  data() {
    return {
      accountInfo: {
        id: "",
        name: "",
        surname: "",
        birthdate: "",
        email: "",
        telephone: "",
        avatar: {
          src: "",
          file: null
        }
      },
      hasOldAvatar: false,
      isAvatarUpdated: false,
      isAvatarDeleted: false
    };
  },
  mounted() {
    this.initAccountInfo();
  },
  computed: {
    profilePictureActions() {
      const actions = [
        {
          label: "page.userAccount.removeProfilePicture",
          callback: this.removeProfilePicture
        },
        {
          label: "page.userAccount.changeProfilePicture",
          callback: this.openUploadDialog
        }
      ];
      if (!this.accountInfo.avatar.src) {
        actions.splice(0, 1);
      }

      return actions;
    }
  },
  methods: {
    initAccountInfo() {
      if (this.$_authUser.profile) {
        this.accountInfo.name = this.$_authUser.profile.name;
        this.accountInfo.surname = this.$_authUser.profile.surname;
        if (this.$_authUser.profile.avatar) {
          this.accountInfo.avatar.src = this.$_getUserProfilePhoto(
            this.$_authUser.profile
          );
          this.hasOldAvatar = true;
        }
        const birthdate = dayjs(this.$_authUser.profile.birthdate);
        this.accountInfo.birthdate = birthdate.isValid()
          ? birthdate.format("YYYY/MM/DD")
          : "";
      }
      this.accountInfo.id = this.$_authUser._id;
      this.accountInfo.email = this.$_authUser.email;
      this.accountInfo.telephone =
        this.$_authUser?.identities?.find(item => {
          return item.type === "mobile" && item.status === "verified";
        })?.identityId || "";
    },
    openUploadDialog() {
      this.$refs.selectAvatar.openSelectFileWindow();
    },
    setProfilePicture(files) {
      if (!files || files.length > 1) return;

      const photoFile = files[0];
      this.accountInfo.avatar.file = photoFile;
      this.accountInfo.avatar.src = URL.createObjectURL(photoFile);
      this.isAvatarUpdated = true;
      this.isAvatarDeleted = true;
    },
    removeProfilePicture() {
      this.accountInfo.avatar.file = null;
      this.accountInfo.avatar.src = "";
      this.isAvatarDeleted = true;
    },
    getProfilePayload() {
      const payload = {
        name: this.accountInfo.name,
        surname: this.accountInfo.surname
      };

      // const birthdate = dayjs(this.accountInfo.birthdate);
      // if (birthdate.isValid())
      //   payload.birthdate = birthdate.format("YYYY-MM-DD");

      if (this.isAvatarUpdated && this.accountInfo.avatar.file)
        payload.avatar = this.accountInfo.avatar.file;
      return payload;
    },
    async saveProfileChanges() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        if (this.hasOldAvatar && this.isAvatarDeleted)
          await this.$store.dispatch("auth/deleteAvatar");

        const userId = this.accountInfo.id;
        await this.$store.dispatch("auth/updateUser", {
          userId,
          adminInfo: this.getProfilePayload()
        });

        await this.$store.dispatch("auth/retrieveUser", userId);

        this.initAccountInfo();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  --image-size: 120px;
  --image-half-size: 60px;
  max-width: 700px;
  .avatar-wrapper {
    position: absolute;
    left: calc(50% - var(--image-half-size));
    top: calc(var(--image-half-size) * -1);
    background: transparent;
    /deep/ {
      .dropdown-toggle {
        position: relative;
        background: transparent;
        border: unset !important;
        padding: 0;
        box-shadow: unset !important;
      }
      .b-avatar-img::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40px;
        width: 100%;
        background: #00000066;
      }
    }
    .avatar {
      width: var(--image-size);
      height: var(--image-size);
    }
    .avatar-caption {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: 4px;
      left: 48px;
    }
  }
  .card-body-container {
    margin-top: calc(var(--image-half-size) + 16px);
  }
}
</style>
